import React, { useEffect } from "react"
import { Grid, Container, Paper } from "@material-ui/core"
import "./styles.css"
import { Typography } from "instadrops-ui"
import { ReactMarkdown } from "react-markdown/lib/react-markdown"
import { callAnalyticsEvent } from "../utils"
import { NavbarLayout } from "../layouts"
import { SPEECH_AGENT_ID } from "../utils/consts"

const WrappedApp = () => {
  return (
    <NavbarLayout agentId={SPEECH_AGENT_ID} persistOpenChat fullScreen>
      <App />
    </NavbarLayout>
  )
}

const App = () => {
  useEffect(() => {
    callAnalyticsEvent("view_privacy_page")
  }, [])

  return <div></div>
}

export default WrappedApp
